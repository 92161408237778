import { css } from '@emotion/react'
import { FC, InputHTMLAttributes } from 'react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

export const checkboxStyles = {
  label: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%'
  }),
  input: css({
    opacity: 0,
    position: 'absolute',
    left: 0,
    ':checked': {
      '~ span': {
        ':before': {
          content: '""',
          position: 'absolute',
          left: 3,
          top: -1,
          width: 6,
          height: 10,
          borderRight: `4px solid ${palette.main}`,
          borderBottom: `4px solid ${palette.main}`,
          transform: 'rotate(45deg)'
        }
      }
    },
    ':focus-visible': {
      '~ span': {
        outline: `2px auto -webkit-focus-ring-color`
      }
    }
  }),
  checkbox: css({
    position: 'relative',
    width: 16,
    height: 16,
    border: `2px solid ${palette.gray3}`,
    borderRadius: 2,
    boxSizing: 'content-box',
    flexShrink: 0,
    marginRight: 8
  }),
  disabled: css({
    color: palette.gray5,
    marginLeft: 28
  })
}

export interface CheckboxWithLabelProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: FC<any>
}

export const CheckboxWithLabel: FC<CheckboxWithLabelProps> = ({
  label,
  as,
  ...rest
}) => {
  const Component = as
  return !rest.disabled ? (
    <label css={[typography.textL, checkboxStyles.label]}>
      {Component ? (
        <Component css={checkboxStyles.input} data-cy={label} {...rest} />
      ) : (
        <input
          type="checkbox"
          css={checkboxStyles.input}
          data-cy={label}
          {...rest}
        />
      )}
      <span css={[checkboxStyles.checkbox]} />
      {label}
    </label>
  ) : (
    <span css={[typography.textL, checkboxStyles.disabled]}>{label}</span>
  )
}
