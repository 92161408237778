import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { CardContent } from '@lifedot/atoms/Card/CardContent'
import { typography } from '@lifedot/styles/typography'

const contentStyles = {
  root: css({
    height: 'calc(85vh - 120px)',
    maxHeight: 500,
    overflow: 'scroll',
    padding: 12
  })
}

export interface PanelContentProps {
  children: ReactNode
  caption?: ReactNode
}

export const PanelContent: FC<PanelContentProps> = ({ caption, children }) => {
  return (
    <CardContent>
      <div css={contentStyles.root}>
        {caption && <p css={typography.textS}>{caption}</p>}
        {children}
      </div>
    </CardContent>
  )
}
