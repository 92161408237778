import type { FC } from 'react'
import { PanelHeader } from '@lifedot/components/SelectPanels/parts/PanelHeader'
import { CitySelectPanel } from './CitySelectPanel'
import { Card } from '@lifedot/atoms/Card'
import { palette } from '@lifedot/styles/palette'
import { css } from '@emotion/react'
import CloseIcon from '@material-ui/icons/Close'
import { useRouter } from 'next/router'
import { graphql } from 'relay-runtime'
import { useFragment } from 'relay-hooks'
import { ShopAreaSelectPannel_Fragment$key } from './__generated__/ShopAreaSelectPannel_Fragment.graphql'

const styles = {
  closeBtn: css({
    color: palette.gray5,
    padding: 0
  })
}

type ShopAreaSelectPanelProps = {
  onClose: () => void
  items: ShopAreaSelectPannel_Fragment$key
}

export const ShopAreaSelectPanel: FC<ShopAreaSelectPanelProps> = ({
  onClose,
  items
}) => {
  const router = useRouter()
  router.events.on('routeChangeStart', onClose)

  const data = useFragment(fragment, items)

  return (
    <Card round={1}>
      <PanelHeader
        title="エリアから探す"
        tools={
          <button css={styles.closeBtn} onClick={onClose}>
            <CloseIcon fontSize="small" />
          </button>
        }
      />
      <CitySelectPanel items={data} />
    </Card>
  )
}

const fragment = graphql`
  fragment ShopAreaSelectPannel_Fragment on CityWithShopCountConnection {
    ...CitySelectPanel_Fragment
  }
`
