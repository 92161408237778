import { type FC } from 'react'
import { ShopListItem } from './ShopListItem/ShopListItem'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { graphql } from 'relay-runtime'
import { useFragment } from 'relay-hooks'
import { ShopList_Fragment$key } from './__generated__/ShopList_Fragment.graphql'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { ShopResultCount } from '../ShopResultCount'
import { mq } from '@lifedot/styles/mediaQuery'

type ShopListProps = {
  shops: ShopList_Fragment$key
}

const styles = {
  listInfo: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginBottom: 12,
    [mq('sp')]: {
      padding: '0px 8px'
    }
  }),
  note: css({
    color: palette.gray6
  })
}
export const ShopList: FC<ShopListProps> = ({ shops }) => {
  const data = useFragment(fragment, shops)
  return (
    <>
      <div css={styles.listInfo}>
        <ShopResultCount />
        <p css={[styles.note, typography.textS]}>
          ※このページにはPRリンクが含まれています
        </p>
      </div>
      <AddMarginWrapper spacing={4}>
        {data.items.map((shop, index) => (
          <ShopListItem key={index} shop={shop} />
        ))}
      </AddMarginWrapper>
    </>
  )
}

const fragment = graphql`
  fragment ShopList_Fragment on ShopResult {
    items {
      ...ShopListItem_Fragment
    }
  }
`
