import { FC } from 'react'
import { css } from '@emotion/react'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { ModalWithButton } from '@lifedot/components/ModalWithButton'
import { SearchButton } from './SearchButton'
import RoomIcon from '@material-ui/icons/Room'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { ShopAreaSelectPanel } from '../ShopAreaSelectPannel'
import { graphql } from 'relay-runtime'
import { useFragment } from 'relay-hooks'
import { SearchButtonsWithPanel_Fragment$key } from './__generated__/SearchButtonsWithPanel_Fragment.graphql'

const styles = {
  wrapper: css({
    [mq('sp')]: {
      padding: '0 8px'
    }
  }),
  button: css({
    borderRadius: 8
  }),
  icon: css({
    color: palette.main,
    verticalAlign: 'middle'
  }),
  condition: css({
    color: palette.gray6
  }),
  empty: css({
    height: 71,
    [mq('sp')]: {
      height: 64
    }
  })
}

type SearchButtonsWithPanelProps = {
  items: SearchButtonsWithPanel_Fragment$key
}

export const SearchButtonsWithPanel: FC<SearchButtonsWithPanelProps> = ({
  items
}) => {
  const data = useFragment(fragment, items)
  return (
    <div css={styles.wrapper}>
      <AddMarginWrapper spacing={3}>
        <ModalWithButton
          button={
            <SearchButton
              titleLabel={
                <>
                  <RoomIcon css={styles.icon} />
                  エリア
                </>
              }
              contentLabel="市区町村を選択"
              css={styles.button}
            />
          }
        >
          {({ handleClose }) => (
            <ShopAreaSelectPanel items={data} onClose={handleClose} />
          )}
        </ModalWithButton>
      </AddMarginWrapper>
    </div>
  )
}
const fragment = graphql`
  fragment SearchButtonsWithPanel_Fragment on CityWithShopCountConnection {
    ...ShopAreaSelectPannel_Fragment
  }
`
