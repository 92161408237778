import { useCallback, useEffect, useMemo, useState } from 'react'
import arraySort from 'array-sort'
import { useRouter, NextRouter } from 'next/router'
import {
  router as lifedotRouter,
  shopListPathWithParamsForNextLink
} from '@lifedot/router'
import { CitySelectPanel_Fragment$data } from './__generated__/CitySelectPanel_Fragment.graphql'
import { useShopListPage } from '@/hooks/shop/use-shop-list-page'

const makeItems = (
  data: CitySelectPanel_Fragment$data | null | undefined
): State['items'] => {
  const items = (data?.items ?? []).map(({ cityId, name, shopCount }) => ({
    value: String(cityId),
    name: `${name}(${shopCount})`,
    disabled: shopCount < 1
  }))
  return arraySort(items, 'value')
}

const getSelectableAllCityIds = (
  data: CitySelectPanel_Fragment$data | null | undefined
): State['selectableValues'] =>
  (data?.items ?? [])
    .filter(({ shopCount }) => shopCount > 0)
    .map(({ cityId }) => String(cityId))

const makeHandleSubmit =
  (
    { roma: prefectureRoma }: ReturnType<typeof useShopListPage>['prefecture'],
    data: CitySelectPanel_Fragment$data | null | undefined,
    router: NextRouter
  ) =>
  (selected: string[]) => {
    const isAll = getSelectableAllCityIds(data).length === selected.length
    const cityId = isAll ? null : selected
    const [pathname, query] = shopListPathWithParamsForNextLink({
      prefectureRoma,
      cityId
    })
    if (router.asPath === lifedotRouter.shopList({ prefectureRoma, cityId })) {
      router.reload()
      return
    }
    router.push({ pathname, query }, { pathname, query })
  }

interface State {
  items: Array<{ value: string; name: string; disabled: boolean }>
  selectableValues: string[]
  resultCount: number | undefined
}

interface Helper {
  handleSubmit: (v: string[]) => void
  handleChange: (v: string[]) => void
}

export const useCitySearchPanelController = (
  searchPanelData: CitySelectPanel_Fragment$data
): [State, Helper] => {
  const { prefecture } = useShopListPage()
  const router = useRouter()
  const [items, setItems] = useState<State['items']>([])
  const [selectableCityIds, setSelectableCityIds] = useState<
    State['selectableValues']
  >([])
  const [resultCount, setResultCount] = useState<number | undefined>(undefined)

  const handleSubmit = useMemo(
    () => makeHandleSubmit(prefecture, searchPanelData, router),
    [searchPanelData, prefecture, router]
  )

  const handleChange = useCallback(
    (ids: string[]) => {
      const allShopCount = searchPanelData.items.reduce(
        (sum, { shopCount, cityId }) => {
          const isSelect = ids.find(
            (selectedCityId) => selectedCityId == cityId.toString()
          )
          return sum + (isSelect ? shopCount : 0)
        },
        0
      )
      setResultCount(allShopCount)
    },
    [searchPanelData.items]
  )

  useEffect(() => {
    if (items.length < 1) setItems(makeItems(searchPanelData))
    if (selectableCityIds.length < 1)
      setSelectableCityIds(getSelectableAllCityIds(searchPanelData))
  }, [items.length, searchPanelData, selectableCityIds.length])

  return [
    {
      items,
      selectableValues: selectableCityIds,
      resultCount
    },
    { handleSubmit, handleChange }
  ]
}
