import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { useShopListPage } from '@/hooks/shop/use-shop-list-page'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  title: css({
    paddingTop: 16,
    lineHeight: 1.4,
    [mq('sp')]: {
      paddingInline: 8
    }
  })
}

export const ShopListPageTitle: FC = () => {
  const {
    prefecture,
    city,
    pagination: { currentPageNumber }
  } = useShopListPage()
  const paging = currentPageNumber > 1 ? `(${currentPageNumber}ページ目)` : ''
  const title = city
    ? `${paging}${city.name}(${prefecture.name})の石材店/墓石販売店`
    : `${paging}${prefecture.name}の石材店/墓石販売店`

  return (
    <h1 css={[typography.textXL, styles.title]}>
      {title}
      <span css={typography.textM}>一覧</span>
    </h1>
  )
}
