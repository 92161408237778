import { FC } from 'react'
import {
  AllSelector,
  CheckBoxList,
  PanelContent,
  PanelFooter,
  useSelected
} from '@lifedot/components/SelectPanels/parts'
import { useCitySearchPanelController } from './dependencies'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { graphql } from 'relay-runtime'
import { useFragment } from 'relay-hooks'
import { CitySelectPanel_Fragment$key } from './__generated__/CitySelectPanel_Fragment.graphql'
import { useShopListPage } from '@/hooks/shop/use-shop-list-page'

const styles = {
  wrap: css({
    border: `1px solid ${palette.gray2}`,
    padding: 12,
    marginTop: 8
  }),
  result: css({
    color: palette.main
  })
}

type CitySelectPanelProps = {
  items: CitySelectPanel_Fragment$key
}

export const CitySelectPanel: FC<CitySelectPanelProps> = ({
  items: cities
}) => {
  const {
    prefecture,
    searchParams: { cityIds }
  } = useShopListPage()
  const data = useFragment(fragment, cities)
  const [{ items, selectableValues, resultCount }, helper] =
    useCitySearchPanelController(data)

  const { selects, setSelects, isAllSelected, handleAllSelect } = useSelected(
    selectableValues,
    cityIds?.map(String),
    helper.handleChange
  )
  return (
    <>
      <PanelContent>
        <AllSelector
          onChange={handleAllSelect}
          checked={isAllSelected}
          label={`${prefecture.name}の全て`}
        />
        <div css={styles.wrap}>
          <CheckBoxList
            name="cities"
            values={selects}
            onChange={setSelects}
            items={items}
          />
        </div>
      </PanelContent>
      <PanelFooter
        buttonLeftText={
          <>
            <span css={styles.result}>{resultCount ?? '…'}</span>
            <small>件</small>
          </>
        }
        buttonText="選択した市区町村で検索"
        onClick={helper.handleSubmit}
        selects={selects}
        disabled={!resultCount}
      />
    </>
  )
}

const fragment = graphql`
  fragment CitySelectPanel_Fragment on CityWithShopCountConnection {
    items {
      cityId: id
      name
      shopCount
    }
  }
`
