import { type FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { useShopListPage } from '@/hooks/shop/use-shop-list-page'

const styles = {
  count: css({
    fontWeight: 'bold',
    flexShrink: 0
  }),
  current: css({
    color: palette.main03
  })
}

export const ShopResultCount: FC = () => {
  const { pagination, total } = useShopListPage()
  return (
    <div css={[typography.textM, styles.count]}>
      <span css={styles.current}>
        {pagination.from + 1}-{pagination.to + 1}
      </span>
      /{total}件
    </div>
  )
}
