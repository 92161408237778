import { FaqAccordion } from '@lifedot/components/FaqAccordion'
import { FC } from 'react'
import { useShopListPage } from '@/hooks/shop/use-shop-list-page'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { router } from '@lifedot/router'

const styles = {
  heading: css({
    [mq('sp')]: {
      paddingInline: 8
    }
  }),
  wrap: css({
    marginTop: 8
  })
}

export const ShopListFaq: FC = () => {
  const { prefecture } = useShopListPage()

  return (
    <section itemProp="text" itemScope itemType="https://schema.org/FAQPage">
      <h2 css={[styles.heading, typography.textXL]}>
        {prefecture.name}
        の石材店・墓石販売店・樹木葬/納骨堂販売業者・寺院に関するよくある質問
      </h2>
      <div css={styles.wrap}>
        <FaqAccordion
          title={`${prefecture.name}にある石材店・墓石販売店・樹木葬/納骨堂販売業者・寺院の費用目安は？`}
        >
          <>
            墓石は、墓地のサイズや彫刻内容に応じてオーダーメイドで制作されるケースが多いため、購入金額は各石材店に直接見積もりを取る必要があります。
            <br />
            <br />
            ライフドットでは、「まずは一般的な墓石の費用相場を知りたい」という方に役立つ墓石カタログをお送りすることが可能です。
            お気軽に<a href={router.lpGraestone()}>カタログ資料請求</a>
            よりお問い合わせください。
          </>
        </FaqAccordion>
        <FaqAccordion
          title={`${prefecture.name}から自分に合った石材店・墓石販売店・樹木葬/納骨堂販売業者・寺院を見つける方法は？`}
        >
          <>
            {prefecture.name}
            で自分に合った業者を見つけるには、まず供養方法を決めることが大切です。
            <br />
            なぜなら供養の種類ごとに、依頼すべき業者が変わってくるからです。
            <br />
            例えば、墓石のお墓で供養をしたい場合は「石材店」や「墓石販売店」、お墓の後継ぎに心配がある方は、樹木葬や納骨堂を取り扱っている「納骨堂販売業者」「寺院」を選びましょう。
            <br />
            {prefecture.name}
            には霊園が多数あり、選べるお墓タイプも多様です。
            <br />
            もしどのような供養方法やお墓タイプが自分に合っているか迷った時は、
            <a href="/stone-sale/">お墓の基礎知識</a>
            をご覧いただくことおすすめいたします。
            <br />
            また、ライフドットにご相談いただければ、お客様のご希望に合った業者のご紹介や、お墓の見学予約などを承ることが可能です。
          </>
        </FaqAccordion>
        <FaqAccordion
          title={`${prefecture.name}でおすすめの寺院の情報はどのように探したらいいですか？`}
        >
          <>
            {prefecture.name}
            で寺院墓地を選ぶ際には、「寺院の雰囲気」「ご住職の供養方針や考え」「立地（通いやすさ）」などの情報を収集する事が大切です。
            <br />
            長年にわたってご先祖様を供養する場所となるため、立地や費用などの条件以外に、供養に対する考え方の相性を確認することをおすすめします。
            <br />
            しかし、寺院に関する情報は公開されていないことも多く、実際にご自身で現地に行って確かめるしかない場合もあります。
            <br />
            ライフドットでは、寺院に関する基礎知識や選び方のポイントなどを情報発信しています。
            <br />
            初めての寺院墓地選びに不安がある方はぜひ下記の記事をご一読ください。
            <br />
            <br />・
            <a href="/ohaka-jiinbochi/">
              寺院墓地の使用料相場は約77万円。檀家料などの費用も必要
            </a>
            <br />・
            <a href="/nokotsudo-temple/">
              お寺の納骨堂を探したい！選ぶときの注意点や利用までの流れも解説！
            </a>
            <br />・
            <a href="/jumokusou-temple/">
              お寺で樹木葬をしたい！檀家は必須？押さえておきたい注意点を解説
            </a>
            <br />・
            <a href="/danka-system/">
              檀家制度ってなに？その成り立ちや実態をくまなく解説
            </a>
          </>
        </FaqAccordion>
      </div>
    </section>
  )
}
