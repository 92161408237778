import { useShopListPage } from '@/hooks/shop/use-shop-list-page'

export const useTitle = (): string => {
  const {
    prefecture,
    city,
    pagination: { currentPageNumber }
  } = useShopListPage()
  const paging = currentPageNumber > 1 ? `(${currentPageNumber}ページ目)` : ''

  return `${paging}${
    city?.name ? city.name : prefecture.name
  }のおすすめ石材店/墓石販売店・樹木葬/納骨専門業者・寺院ランキング | 対応できるお墓（霊園・墓地）の資料を無料請求`
}

export const useDescription = (): string => {
  const { prefecture, city } = useShopListPage()

  return `${
    city?.name ? city.name : prefecture.name
  }のおすすめ石材店/墓石販売店や樹木葬/納骨堂、宗教法人/寺院の評判・アクセスを紹介。お墓の購入や費用目安、無料での資料請求方法など、必要な情報を一覧でご提供。近くの霊園・墓地や、近隣での永代供養についても相談を受け付けております。会社の信頼性や特徴、相場情報まで網羅しており、お墓選びに役立つ情報が満載です。対応できるお墓の種類も豊富で、お客様のニーズに合わせた最適な提案を行います。お墓のことなら、ライフドットにお任せください`
}
