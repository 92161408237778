import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { CardFooter } from '@lifedot/atoms/Card/CardFooter'
import { Button } from '@lifedot/atoms/Button'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { Selects } from './use-selected'

const footerStyles = {
  btnBox: css({
    width: '50%',
    [mq('sp')]: {
      flex: 1
    }
  }),
  root: css({
    background: palette.black,
    padding: '12px 12px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  text: css({
    color: palette.white,
    fontWeight: 'bold',
    marginRight: 8,
    minWidth: 68,
    textAlign: 'right'
  })
}

export interface PanelFooterProps {
  buttonLeftText?: ReactNode
  buttonText: string
  onClick: (v: Selects) => void
  selects: Selects
  disabled?: boolean
}

export const PanelFooter: FC<PanelFooterProps> = ({
  buttonLeftText,
  buttonText,
  onClick,
  selects,
  disabled = false
}) => (
  <CardFooter>
    <div css={footerStyles.root}>
      {buttonLeftText && (
        <p css={[typography.textXL, footerStyles.text]}>{buttonLeftText}</p>
      )}
      <div css={footerStyles.btnBox}>
        <Button
          mainText={<span css={typography.textL}>{buttonText}</span>}
          onClick={() => onClick(selects)}
          data-cy="selectPanelSubmitBtn"
          disabled={disabled}
        />
      </div>
    </div>
  </CardFooter>
)
