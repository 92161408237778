/**
 * @generated SignedSource<<32952b4658c35679b724b102e99fd347>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type CitySelectPanel_Fragment$data = {
  readonly items: ReadonlyArray<{
    readonly cityId: number
    readonly name: string
    readonly shopCount: number
  }>
  readonly ' $fragmentType': 'CitySelectPanel_Fragment'
}
export type CitySelectPanel_Fragment$key = {
  readonly ' $data'?: CitySelectPanel_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'CitySelectPanel_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'CitySelectPanel_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CityWithShopCount',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: 'cityId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'shopCount',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CityWithShopCountConnection',
  abstractKey: null
}

;(node as any).hash = 'b93de470345c1edc55edb5b7e7e21dcf'

export default node
