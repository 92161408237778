import { FC } from 'react'
import { css } from '@emotion/react'
import { Pagination } from './Pagination'

const styles = {
  root: css({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    'nav ul': {
      justifyContent: 'center'
    }
  })
}

export const ShopPaginationStyled: FC = () => {
  return (
    <div css={styles.root}>
      <Pagination />
    </div>
  )
}
