/**
 * @generated SignedSource<<621d3e04a6da46911f5e751781b3866a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ShopAreaSelectPannel_Fragment$data = {
  readonly ' $fragmentSpreads': FragmentRefs<'CitySelectPanel_Fragment'>
  readonly ' $fragmentType': 'ShopAreaSelectPannel_Fragment'
}
export type ShopAreaSelectPannel_Fragment$key = {
  readonly ' $data'?: ShopAreaSelectPannel_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ShopAreaSelectPannel_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ShopAreaSelectPannel_Fragment',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'CitySelectPanel_Fragment'
    }
  ],
  type: 'CityWithShopCountConnection',
  abstractKey: null
}

;(node as any).hash = '32f1f0dd5f79deb962cf4d3ce60bf242'

export default node
