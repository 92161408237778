/**
 * @generated SignedSource<<f7f21307f677acd49616672b84fd1f2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ShopList_Fragment$data = {
  readonly items: ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ShopListItem_Fragment'>
  }>
  readonly ' $fragmentType': 'ShopList_Fragment'
}
export type ShopList_Fragment$key = {
  readonly ' $data'?: ShopList_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ShopList_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ShopList_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Shop',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'ShopListItem_Fragment'
        }
      ],
      storageKey: null
    }
  ],
  type: 'ShopResult',
  abstractKey: null
}

;(node as any).hash = '7eb9f33fd0de2422bc79b47fd2d9704b'

export default node
