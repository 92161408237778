/**
 * @generated SignedSource<<c34846b2ed447ca239add8707319e63d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ShopListItem_Fragment$data = {
  readonly shopId: number
  readonly address: string
  readonly first_description_title: string
  readonly first_description: string
  readonly name: string
  readonly kana: string
  readonly ' $fragmentType': 'ShopListItem_Fragment'
}
export type ShopListItem_Fragment$key = {
  readonly ' $data'?: ShopListItem_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ShopListItem_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ShopListItem_Fragment',
  selections: [
    {
      alias: 'shopId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'address',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'first_description_title',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'first_description',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'kana',
      storageKey: null
    }
  ],
  type: 'Shop',
  abstractKey: null
}

;(node as any).hash = 'bfd39567645fba88803f8d76abbf596c'

export default node
