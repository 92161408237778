import { FC } from 'react'
import { css } from '@emotion/react'
import { List, ListItem } from '@lifedot/atoms/List'
import { mq } from '@lifedot/styles/mediaQuery'
import { Selects } from './use-selected'
import { CheckboxWithLabel } from './CheckboxWithLabel'
import { CheckboxGroup, Checkbox } from 'react-checkbox-context'

const checkboxListStyles = {
  checkGroup: css({
    ul: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '8px 12px',
      [mq('sp')]: {
        gridTemplateColumns: '1fr',
        gap: 12
      }
    }
  })
}

export interface CheckBoxListProps {
  name: string
  values: Selects
  onChange: (value: Selects) => void
  items: Array<{ value: string; name: string; disabled?: boolean }>
}

export const CheckBoxList: FC<CheckBoxListProps> = ({
  items,
  onChange,
  ...rest
}) => {
  const handleChange = (_e: unknown, values: Selects) => onChange(values)
  return (
    <div css={checkboxListStyles.checkGroup}>
      <List>
        <CheckboxGroup onChange={handleChange} {...rest}>
          {items.map(({ name, value, disabled }) => (
            <ListItem key={value}>
              <CheckboxWithLabel
                as={Checkbox}
                label={name}
                value={value}
                disabled={disabled}
              />
            </ListItem>
          ))}
        </CheckboxGroup>
      </List>
    </div>
  )
}
