import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { CardHeader } from '@lifedot/atoms/Card/CardHeader'
import { typography } from '@lifedot/styles/typography'

const headerStyles = {
  root: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  })
}

export interface PanelHeaderProps {
  title: string
  tools?: ReactNode
}

export const PanelHeader: FC<PanelHeaderProps> = ({ title, tools }) => (
  <CardHeader spacing={3} border>
    <div css={headerStyles.root}>
      <p css={typography.headingXS}>{title}</p>
      {tools}
    </div>
  </CardHeader>
)
