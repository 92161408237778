import { FC, Fragment } from 'react'
import { Accordion } from '@lifedot/atoms/Accordion'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { CheckBoxList, CheckBoxListProps } from './'
import { css } from '@emotion/react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export interface PanelAccordionGroupProps {
  groupedItems: Array<{
    name: AccordionGroupProps['name']
    title?: TitleProps['name']
    items: CheckBoxListProps['items']
  }>
  selects: CheckBoxListProps['values']
  setSelects: CheckBoxListProps['onChange']
}

export const PanelAccordionGroup: FC<PanelAccordionGroupProps> = ({
  groupedItems,
  selects,
  setSelects
}) => {
  return (
    <div>
      {groupedItems.map((groupedItem) => (
        <Fragment key={groupedItem.name}>
          {groupedItem.title && <Title name={groupedItem.title} />}
          <AccordionGroup
            name={groupedItem.name}
            selects={selects}
            setSelects={setSelects}
            items={groupedItem.items}
          />
        </Fragment>
      ))}
    </div>
  )
}

const titleStyles = css({
  background: palette.gray1,
  padding: '4px 8px',
  ':not(:first-of-type)': {
    marginTop: 12
  }
})

interface TitleProps {
  name: string
}

const Title: FC<TitleProps> = ({ name }) => (
  <p css={[typography.textS, titleStyles]}>{name}</p>
)

const accordionGroupStyles = {
  root: css({
    borderBottom: `1px solid ${palette.gray2}`,
    '> [open] svg': {
      transform: 'rotate(180deg)'
    }
  }),
  label: css({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 8px',
    cursor: 'pointer',
    lineHeight: '44px'
  }),
  text: css({
    flex: '1 1'
  }),
  content: css({
    paddingBottom: 16
  })
}

interface AccordionGroupProps {
  name: string
  selects: CheckBoxListProps['values']
  setSelects: CheckBoxListProps['onChange']
  items: CheckBoxListProps['items']
}

const AccordionGroup: FC<AccordionGroupProps> = (props) => {
  const { name, selects, setSelects, items } = props
  return (
    <div css={accordionGroupStyles.root}>
      <Accordion
        vanishing
        title={
          <p css={accordionGroupStyles.label}>
            <span css={accordionGroupStyles.text}>{name}</span>
            <ExpandMoreIcon fontSize="small" />
          </p>
        }
      >
        <div css={accordionGroupStyles.content}>
          <CheckBoxList
            name={name}
            values={selects}
            onChange={setSelects}
            items={items}
          />
        </div>
      </Accordion>
    </div>
  )
}
