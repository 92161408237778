import { FC, useCallback } from 'react'
import { Pagination as PaginationMikage } from '@lifedot/atoms/Pagination'
import { router } from '@lifedot/router'
import { fill } from '@lifedot/utils'
import { useShopListPage } from '@/hooks/shop/use-shop-list-page'
import { PaginationItem } from './ShopPaginationItem'

export const Pagination: FC = () => {
  const {
    prefecture: { roma: prefectureRoma },
    city,
    searchParams: { sort, cityIds },
    pagination
  } = useShopListPage()
  const makeHref = useCallback(
    (page: number) =>
      router.shopList({
        prefectureRoma: prefectureRoma,
        cityId: cityIds || city?.cityId,
        order: sort.field,
        page: page
      }),
    [cityIds, prefectureRoma, sort.field, city?.cityId]
  )
  const totalPages = fill(1, pagination.lastPageNumber)

  return (
    <PaginationMikage current={pagination.currentPageNumber}>
      {totalPages.map((page) => (
        <PaginationItem index={page} href={makeHref(page)} key={page} />
      ))}
    </PaginationMikage>
  )
}
