import { nullifyWhenEmptyArray } from '@lifedot/utils'
import { defaultSort, Sort, sorts } from '@lifedot/constants/sort'
import { ParsedUrlQuery } from 'querystring'

export interface SearchParams {
  cityIds: Array<string> | null
  from: number
  sort: Sort
}

const PER_PAGE = 20 as const

export const getSearchParamsByQuery = (
  query: ParsedUrlQuery,
  cityId?: string,
  options?: {
    defaultSort?: string
  }
): SearchParams => {
  const cityIds = nullifyWhenEmptyArray([
    ...(getParams('city_ids', query) || []),
    ...(cityId ? [cityId] : [])
  ])

  const _page = query['page']
  const page = Number(Array.isArray(_page) ? _page[0] : _page) ?? 1
  const from = ((Number.isInteger(page) ? page : 1) - 1) * PER_PAGE

  const _sort = query['order']
  const sortKey = (Array.isArray(_sort) ? _sort[0] : _sort) ?? ''
  const sort =
    sorts[sortKey] ?? sorts[options?.defaultSort ?? 'default'] ?? defaultSort

  return {
    cityIds,
    from,
    sort
  }
}

const getParams = (key: string, query: ParsedUrlQuery) => {
  const target = query[`f[${key}][]`]
  if (!target) return null
  return Array.isArray(target) ? target : [target]
}
