/**
 * @generated SignedSource<<e4acc3c841fff2296724c8cce354d574>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type SearchButtonsWithPanel_Fragment$data = {
  readonly ' $fragmentSpreads': FragmentRefs<'ShopAreaSelectPannel_Fragment'>
  readonly ' $fragmentType': 'SearchButtonsWithPanel_Fragment'
}
export type SearchButtonsWithPanel_Fragment$key = {
  readonly ' $data'?: SearchButtonsWithPanel_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'SearchButtonsWithPanel_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'SearchButtonsWithPanel_Fragment',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'ShopAreaSelectPannel_Fragment'
    }
  ],
  type: 'CityWithShopCountConnection',
  abstractKey: null
}

;(node as any).hash = '01813b6cb46d1d443f93679c19261f6c'

export default node
