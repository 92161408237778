import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { palette } from '@lifedot/styles/palette'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import RoomIcon from '@material-ui/icons/Room'
import { graphql } from 'relay-runtime'
import { useFragment } from 'relay-hooks'
import { ShopListItem_Fragment$key } from './__generated__/ShopListItem_Fragment.graphql'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { EventTracker } from '@lifedot/tracking'

const styles = {
  anchor: css({
    textDecoration: 'none'
  }),
  wrap: css({
    backgroundColor: palette.white,
    border: `1px solid ${palette.gray3}`,
    borderTopWidth: 2,
    cursor: 'pointer'
  }),
  name: css({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '4px 8px 8px',
    borderBottom: `1px solid ${palette.gray3}`
  }),
  heading: css({
    textDecoration: 'underline'
  }),
  content: css({
    display: 'grid',
    gap: 8,
    padding: 8,
    gridTemplateColumns: '100%'
  }),
  access: css({
    fontWeight: 'bold',
    svg: css({
      color: palette.gray5,
      verticalAlign: -4,
      marginRight: 4
    })
  }),
  feature: css({
    padding: 8,
    backgroundColor: palette.background
  }),
  description: css({
    marginTop: 4
  })
}

type ShopListItemType = {
  shop: ShopListItem_Fragment$key
}
export const ShopListItem: FC<ShopListItemType> = ({ shop }) => {
  const {
    shopId,
    name,
    address,
    first_description,
    first_description_title,
    kana
  } = useFragment(fragment, shop)
  return (
    <EventTracker
      label={`ShopCard_${shopId}`}
      action="click"
      elementLabel={`shop_${shopId}`}
    >
      <Link href={router.shop(shopId)}>
        <a css={styles.anchor}>
          <article css={styles.wrap}>
            <div css={styles.name}>
              <div>
                <span css={typography.textXS}>{kana}</span>
                <h2 css={[styles.heading, typography.textXL]}>{name}</h2>
              </div>
              <ChevronRightIcon fontSize="medium" />
            </div>
            <div css={styles.content}>
              <p css={[styles.access, typography.textM]}>
                <RoomIcon fontSize="small" />
                {address}
              </p>
              <div css={styles.feature}>
                <h3 css={typography.textM}>{first_description_title}</h3>
                <p css={[typography.textS, styles.description]}>
                  {first_description}
                </p>
              </div>
            </div>
          </article>
        </a>
      </Link>
    </EventTracker>
  )
}

const fragment = graphql`
  fragment ShopListItem_Fragment on Shop {
    shopId: id
    address
    first_description_title
    first_description
    name
    kana
  }
`
