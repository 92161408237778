import {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction
} from 'react'
import equal from 'fast-deep-equal'

export type Selects = Array<string>

export interface SelectHelper {
  selects: Selects
  setSelects: Dispatch<SetStateAction<Selects>>
  isAllSelected: boolean
  handleAllSelect: () => void
}

export const useSelected = (
  items: Selects,
  initials?: Selects,
  changeHook?: (v: Selects) => void
): SelectHelper => {
  const [selects, setSelectsNoSort] = useState<Selects>(initials ?? [])
  const setSelects = useCallback<Dispatch<SetStateAction<Selects>>>(
    (arg: Selects | ((prev: Selects) => Selects)) => {
      if (typeof arg === 'function')
        setSelectsNoSort((prev) => arg(prev).sort())
      else setSelectsNoSort(arg.sort())
    },
    []
  )
  const sortedItems = useMemo(() => items.sort(), [items])
  const isAllSelected = useMemo(() => equal(sortedItems, selects), [
    sortedItems,
    selects
  ])
  const handleAllSelect = useCallback(() => {
    setSelectsNoSort((prev) => {
      if (equal(sortedItems, prev)) return []
      return sortedItems
    })
  }, [sortedItems])

  const prev = useRef<typeof selects>(selects)
  useEffect(() => {
    if (!equal(prev.current, selects)) {
      changeHook?.(selects)
      prev.current = selects
    }
  }, [selects, changeHook])
  return { selects, setSelects, isAllSelected, handleAllSelect }
}
